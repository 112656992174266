import React from "react";
import Copyright from "./copyright";
import Sitemap from "./sitemap";

const Footer = () => {
  return (
    <>
      <footer className="footer__wrap">
        <Sitemap />
        <Copyright />
      </footer>
    </>
  );
};

export default Footer;

import React, { useRef } from "react";
import { useState } from "react";
import { useOnClickOutside } from "../../hooks";

const Tooltip = ({ tooltipIcon, tooltipText }) => {
  const [isActive, setIsActive] = useState(false);

  const tooltipRef = useRef();
  useOnClickOutside(tooltipRef, () => setIsActive(false));

  function handleClick() {
    setIsActive(!isActive);
  }

  return (
    <>
      <div
        className={`tooltip__wrap ${isActive ? "active" : ""}`}
        ref={tooltipRef}
      >
        <div className="tooltip__icon" onClick={handleClick}>
          {/* {!isActive ? (
            <i className={tooltipIcon}></i>
          ) : (
            <i className="siw-close"></i>
          )} */}
          <i className={tooltipIcon ? tooltipIcon : "edi-info"}></i>
        </div>
        {isActive ? <div className="tooltip__box">{tooltipText}</div> : ""}
        {/* {tooltipLable && <label className="tooltip__lable">{tooltipLable}</label>} */}
      </div>
    </>
  );
};

export default Tooltip;

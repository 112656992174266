import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import DiscoverNowButton from "../discoverNowButton";
import OurCommunitySwiper from "../ourCommunitySwiper";
import { NavLink, useNavigate } from "react-router-dom";
import { getPath } from "../../utils";
import { useGetSectionHeadingsQuery } from "../../api/services/home";

const OurCommunity = () => {
  const navigate = useNavigate();
  const { data: sectionHeadings } = useGetSectionHeadingsQuery();

  return (
    <>
      <section
        className="section__row  section--ourCommunity"
        data-aos="fade-up"
      >
        <div className="main__container">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={sectionHeadings?.our_community?.our_community_title}
            />
            <SectionCaption
              caption={sectionHeadings?.our_community?.our_community_text}
            />
          </div>
        </div>
        <div className="main__container--fluid">
          <div className="ourCommunity__swiper">
            <OurCommunitySwiper />
          </div>
        </div>

        <div className="main__container">
          <div className="ourCommunity__cta">
            <NavLink to={getPath("ednoverse")}>
              <DiscoverNowButton
                onClickProp={() => {
                  navigate(getPath("ednoverse"));
                }}
              />
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurCommunity;

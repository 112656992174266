import React from "react";

const InputCheckbox = ({
  extraCls,
  checkboxID,
  label,
  onChange, // Pass the onChange function from props
  components,
  checkboxState,
  checked,
}) => {
  return (
    <>
      <div className={`checkBox ${extraCls ? extraCls : ""}`}>
        <input
          type="checkbox"
          id={checkboxID}
          value={checkboxID}
          onChange={onChange} // Use the onChange function from props
          disabled={checkboxState}
          checked={checked}
        />

        <label htmlFor={checkboxID}>
          {label && <div className="checkBox__lbl"> {label}</div>}
          {components}
        </label>
      </div>
    </>
  );
};

export default InputCheckbox;

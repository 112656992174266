import React from "react";
import { useGetBranchByStatesQuery } from "../../api/services/contactus";
import Map from "../map";

const HEAD_OFFICE_POSITION = {
  latitude: "19.2302479",
  longitude: "72.8541329",
  address:
    "4th floor, Ajanta Square Building, near Borivali court, Sundar Nagar, Borivali West, Mumbai, Maharashtra 400092",
};
const EdnovateMap = () => {
  const { data: branches, isLoading: isBranchesLoading } =
    useGetBranchByStatesQuery();
  if (isBranchesLoading || !branches?.length) {
    return <></>;
  }
  return (
    <Map
      markers={[
        {
          position: [
            Number(HEAD_OFFICE_POSITION?.latitude),
            Number(HEAD_OFFICE_POSITION?.longitude),
          ],
          address: HEAD_OFFICE_POSITION?.address,
        },
      ]}
    />
  );
};

export default EdnovateMap;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Overlays } from "../overlays";
import { home } from "../../redux/actions";
import BranchCard from "../branchCard";

const BranchOverlay = () => {
  const dispatch = useDispatch();

  const homeState = useSelector((state) => state?.app?.home);

  function toggleBranchOverlay(val = false, branch = {}) {
    dispatch(
      home.setShowBranchOverlay({
        isBranchOverlayOpen: val,
        selectedBranch: branch,
      })
    );
  }

  if (homeState?.isBranchOverlayOpen) {
    return (
      <Overlays
        overlayName={"branches"}
        overlayConfig={{
          noOverlayHead: false,
          noOverlayFoot: false,
          onClickProp: () => {
            toggleBranchOverlay();
          },
        }}
      >
        <BranchCard
          branchName={homeState?.selectedBranch?.city}
          branchAddress={homeState?.selectedBranch?.address}
          mapLink={homeState?.selectedBranch?.map_link}
          branchNumber={homeState?.selectedBranch?.mobile_no}
          toggleBranchOverlay={toggleBranchOverlay}
        />
      </Overlays>
    );
  } else {
    return null;
  }
};

export default BranchOverlay;

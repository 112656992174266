import React from "react";

const HexagonClipMask = ({ id, thumb }) => {
  return (
    <>
      <svg
        width="540"
        height="540"
        viewBox="0 0 540 540"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3547_6234)">
          <path
            className="hexStroke"
            d="M250 11.547C262.376 4.40169 277.624 4.40169 290 11.547L483.827 123.453C496.203 130.598 503.827 143.803 503.827 158.094V381.906C503.827 396.197 496.203 409.402 483.827 416.547L290 528.453C277.624 535.598 262.376 535.598 250 528.453L56.1731 416.547C43.7971 409.402 36.1731 396.197 36.1731 381.906V158.094C36.1731 143.803 43.7971 130.598 56.1731 123.453L250 11.547Z"
          />
        </g>
        <defs>
          <mask id={`image${id}`}>
            <path
              d="M250.009 87.547C262.385 80.4017 277.633 80.4017 290.009 87.547L418.018 161.453C430.394 168.598 438.018 181.803 438.018 196.094V343.906C438.018 358.197 430.394 371.402 418.018 378.547L290.009 452.453C277.633 459.598 262.385 459.598 250.009 452.453L122 378.547C109.624 371.402 102 358.197 102 343.906V196.094C102 181.803 109.624 168.598 122 161.453L250.009 87.547Z"
              fill="white"
            />
          </mask>
        </defs>
        <image
          mask={`url(#image${id})`}
          width="540"
          height="540"
          transform="translate(0,0)"
          xlinkHref={thumb}
        />
      </svg>
    </>
  );
};

export default HexagonClipMask;

import React, { useRef } from "react";
import InputLabel from "../inputLbl";
import { CtaButton } from "../../ctaButton";

const InputField = ({
  parentExtraCls,
  isIcon,
  inputLbl,
  inputID,
  inputType = "text",
  inputTooltipText,
  placeholderLabel,
  isDisabled = false,
  inputText,
  handleInput,
  extraAttri = {},
  components,
  inputValidate,
  registerFunction,
  onBlur = () => {},
  iconClickHandler,
}) => {
  const parentDiv = useRef(null);

  function handleBlur(e) {
    onBlur(e);
  }
  const { ref = null, ...rest } = registerFunction
    ? registerFunction(inputID)
    : {};

  return (
    <>
      <div
        ref={parentDiv}
        className={`input ${parentExtraCls ? parentExtraCls : ""} ${
          inputValidate ? "invalid" : ""
        }`}
      >
        {inputLbl ? (
          <InputLabel
            inputLbl={inputLbl}
            inputID={inputID}
            inputTooltipText={inputTooltipText}
          />
        ) : (
          ""
        )}

        <div className="input__field">
          <input
            autoComplete="off"
            name={inputID}
            type={inputType}
            id={inputID}
            placeholder={placeholderLabel}
            disabled={isDisabled}
            value={inputText}
            onChange={handleInput}
            onFocus={() => {}}
            onBlur={handleBlur}
            {...extraAttri}
            ref={ref}
            {...rest}
          />

          {isIcon ? (
            <div className="input__icon">
              <CtaButton
                btnCls={"btn btn__icon"}
                iconCls={isIcon}
                onClickProp={() => iconClickHandler && iconClickHandler()}
                {...isIcon}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {components ? components : null}
      </div>
    </>
  );
};

export { InputField };

import React, { useEffect, useState } from "react";
// import Dropdown from "../dropdown";
import InputSearchBox from "../forms/inputSearchBox";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCoursesByChapterQuery,
  useGetSubjectChapterListingQuery,
} from "../../api/services/courses";
import CourseCard from "../courseCard";
import { CtaButton } from "../ctaButton";
import FilterOverlay from "../filterOverlay";
import { useDebounce } from "../../hooks/useDebounce";
import { coursesData } from "../../api/services/courses";
import { NoDataFound } from "../noDataFound";

const LIMIT = 6;

const CoursesByChapter = () => {
  // const masterData = useSelector(
  //   (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  // );
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state?.app?.home);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 1500);
  const [filteredSearchTerm, setFilteredSearchTerm] =
    useState(debouncedSearchTerm);
  const [pageNo, setPageNo] = useState(0);
  const [previousItems, setPreviousItems] = useState(0);
  const [currentItems, setCurrentItems] = useState(0);

  const { data } = useGetCoursesByChapterQuery({
    batchID: homeState?.activeCourseType,
    classID: homeState?.activeClassType,
    learningMode: homeState?.activeLearningType,
    search: filteredSearchTerm,
    chapterID: selectedChapters.join(","),
    subjectID: selectedSubjects.join(","),
    limit: LIMIT,
    offset: pageNo,
  });
  const { data: filterData } = useGetSubjectChapterListingQuery({
    batchID: homeState?.activeCourseType,
    classID: homeState?.activeClassType,
    learningMode: homeState?.activeLearningType,
  });

  function loadMore() {
    setPreviousItems(currentItems);
    setPageNo((val) => val + 1);
  }

  const [isFilterOverlayOpen, setIsFilterOverlayOpen] = useState();
  function toggleFilterOverlay(selectedSubjects, selectedChapters) {
    if (selectedSubjects?.length) {
      setSelectedSubjects(selectedSubjects);
      setPageNo(0);
    }

    if (selectedChapters?.length) {
      setSelectedChapters(selectedChapters);
      setPageNo(0);
    }

    setIsFilterOverlayOpen((value) => !value);
  }

  useEffect(() => {
    if (debouncedSearchTerm.length === 0 || debouncedSearchTerm.length > 1) {
      setPageNo(0);
      setPreviousItems(0);
      setCurrentItems(0);
      setFilteredSearchTerm(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setCurrentItems(data?.length);
  }, [data]);

  if (!data?.length && searchTerm === "") {
    return null;
  }

  return (
    <>
      <div className="onlineCourses__row" data-aos="fade-up">
        <div className="main__container">
          <h3 className="onlineCourses__heading">{"By Chapter"}</h3>
          <div className="filters__wrap">
            <div className="filters__grid">
              <div className="filters__grid-lhs">
                <CtaButton
                  btnCls={"btn btn__primary btn__round"}
                  btnText={"Filter"}
                  iconCls={"edi-filter"}
                  onClickProp={toggleFilterOverlay}
                />
                {/* <Dropdown
                customCls={"dropdown--grad"}
                list={masterData?.courses}
                value={masterData?.courses[0]}
                handleClick={() => {}}
              />
              <Dropdown
                customCls={"dropdown--grad"}
                list={masterData?.language}
                value={masterData?.language[0]}
                handleClick={() => {}}
              /> */}
                {/* <Dropdown
                customCls={"dropdown--grad"}
                list={sortArr}
                value={sortArr[0]}
                handleClick={() => {}}
              /> */}
              </div>
              <div className="filters__grid-rhs">
                <div className="filters__search">
                  <InputSearchBox
                    searchConfig={{ parentExtraCls: "input--searchBox" }}
                    srchPlaceholder={"Search"}
                    srchID={"filter_srch_by_subject"}
                    inputValue={searchTerm}
                    handleInput={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    clearInputValue={(e) => {
                      setSearchTerm("");
                      dispatch(coursesData.util.invalidateTags(["ByChapter"]));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="course__wrap">
          <div className="main__container">
            <div className="onlineCourses__grid">
              {!data?.length && <NoDataFound noDataTitle={"No Data Found"} />}
              {data?.map((item) => (
                <CourseCard
                  id={item?.prod_id}
                  key={"by-chapter-" + item?.id}
                  thumbnail={item.thumbnail}
                  title={item.title}
                  catergory={item?.class_id}
                  batch={item?.batch_id}
                  rating={item.rating}
                  proposerThumb={item?.professor?.image}
                  proposerName={item?.professor?.name}
                  publishDate={item?.start_date}
                  enrolledStudentThumb={item.enrolled_students?.images}
                  enrolledCount={item?.count}
                  courseDiscountPrice={item?.discounted_price}
                  coursePrice={item?.actual_price}
                />
              ))}
            </div>
          </div>
          {previousItems < currentItems && (
            <div className="main__container">
              <div className="onlineCourses__cta">
                <CtaButton
                  btnCls={"btn btn__primary"}
                  iconCls={"edi-loadmore"}
                  btnText={"Load More"}
                  onClickProp={loadMore}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isFilterOverlayOpen && (
        <FilterOverlay
          handleApply={toggleFilterOverlay}
          data={filterData}
          selSubjects={selectedSubjects}
          selChapters={selectedChapters}
        />
      )}
    </>
  );
};

export default CoursesByChapter;

import React from "react";

const Tag = ({ tagName, tagID }) => {
  function getTagData(type) {
    switch (type) {
      case 20:
      case "20":
        return {
          color: "var(--ascent-5)",
          text: "FYJC",
        };
      case 19:
      case "19":
        return {
          color: "var(--ascent-1)",
          text: "SYJC",
        };
      case 17:
      case "17":
        return {
          color: "var(--ascent-3)",
          text: "CA Inter",
        };
      case 18:
      case "18":
        return {
          color: "var(--ascent-4)",
          text: "CA Foundation",
        };
      case 16:
      case "16":
        return {
          color: "var(--ascent-1)",
          text: "CA Final",
        };
      case 32:
      case "32":
        return {
          color: "var(--ascent-8)",
          text: "Test Series",
        };
      case 33:
      case "33":
        return {
          color: "var(--ascent-2)",
          text: "CMA Final",
        };
      case 34:
      case "34":
        return {
          color: "var(--ascent-3)",
          text: "CMA Inter",
        };
      case 35:
      case "35":
        return {
          color: "var(--ascent-4)",
          text: "CMA Foundation",
        };
      case 36:
      case "36":
        return {
          color: "var(--ascent-5)",
          text: "CMA (US) - I",
        };
      case 37:
      case "37":
        return {
          color: "var(--ascent-5)",
          text: "CMA (US) - II",
        };
      case 38:
      case "38":
        return {
          color: "var(--ascent-5)",
          text: "ACCA",
        };
      case 39:
      case "39":
        return {
          color: "var(--ascent-5)",
          text: "FRM (US)",
        };
      default:
        return {
          // color: "var(--ascent-8)",
          // text: "Tag Name",
        };
    }
  }

  return (
    <>
      <div className="tag">
        <div
          className="tag__box"
          style={{ backgroundColor: getTagData(tagID).color }}
        >
          {tagName ? tagName : getTagData(tagID).text}
        </div>
      </div>
    </>
  );
};

export default Tag;

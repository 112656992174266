import React, { useCallback, useEffect } from "react";
import { CtaButton } from "../../ctaButton";

const OverlayFooter = ({
  cancelBtn,
  submitBtn,
  btnPrimary,
  btnPrimaryIcon,
  btnSecondary,
  btnSecondaryIcon,
  isDisabled,
  onCancelClick = () => {
    return null;
  },
  formSubmitRef,
  onConfirmClick = () => {
    formSubmitRef?.current?.submitForm();
  },
  customFooter = null,
}) => {
  useEffect(() => {
    const BODY_CLASS = "noBodyScroll";
    document.body.classList.add(BODY_CLASS);
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.body.classList.remove(BODY_CLASS);
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape" && cancelBtn) {
      onCancelClick();
    }
  }, []);

  return (
    <>
      <div className="popup__foot">
        {cancelBtn && (
          <CtaButton
            btnCls="btn btn__tertiary btn__tertiary--outline"
            iconCls={btnSecondaryIcon}
            btnText={btnSecondary}
            ariaLabel={btnSecondary}
            onClickProp={onCancelClick}
          />
        )}
        {submitBtn && (
          <CtaButton
            btnCls="btn btn__primary"
            iconCls={btnPrimaryIcon}
            btnText={btnPrimary}
            ariaLabel={btnPrimary}
            onClickProp={() => {
              onConfirmClick();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !isDisabled) {
                e.preventDefault();
                onConfirmClick();
              }
            }}
            btnState={isDisabled}
          />
        )}
        {customFooter && customFooter}
      </div>
    </>
  );
};

export default OverlayFooter;

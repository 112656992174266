import React from "react";
// import Anchor from "../../anchor";
import { Link } from "react-router-dom";
import { getPath } from "../../../utils";

const Copyright = () => {
  const staticPageArr = [
    {
      pageName: "Terms and conditions",
      pageURL: "terms",
    },
    {
      pageName: "Privacy",
      pageURL: "privacy",
    },
    {
      pageName: "Refund Policy",
      pageURL: "refund",
    },
  ];
  return (
    <>
      <div className="main__container">
        <div className="copyright__wrap">
          <div className="copyright__grid">
            <div className="copyright__text">
              © {new Date().getFullYear()} Ednovate Edtech Pvt. Ltd.
            </div>
            <div className="static__link">
              <ul>
                {staticPageArr.map((item, index) => (
                  <li key={index}>
                    <Link to={getPath(item?.pageURL)}>{item?.pageName}</Link>
                    {/* <Anchor linkText={item.pageName} hyperLink={item.pageURL} /> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Copyright;

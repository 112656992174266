import React from "react";
import Media from "../media";
import { IMAGE } from "../../common/config";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import { useGetWhyEdnovateQuery } from "../../api/services/aboutus";

// const whyEdnovateKeywordArr = [
//   "Interactive Learning Environment: Engaging classrooms foster critical thinking and problem-solving skills.",
//   "Customized Study Plans: Tailored to individual strengths and weaknesses for personalized attention.",
//   "Mock Exams and Regular Assessments: Prepares students effectively for CA exams.",
//   "Exclusive Scholarship Opportunities: Rewarding talent and hard work for financial aid.",
//   "Career Counseling and Placement Assistance: Equips students for professional success.",
//   "PAN India Presence: Convenient access to quality CA coaching nationwide.",
//   "Robust Alumni Network: Access to mentorship, networking, and industry insights.",
// ];

const WhyEdnovate = () => {
  const { data } = useGetWhyEdnovateQuery();

  return (
    <>
      <section className="section__row  section--aboutUs section--whyEdnovate">
        <div className="main__container">
          <div className="whyEdnovate">
            <div className="whyEdnovate__grid">
              <div className="whyEdnovate__grid-lhs" data-aos="zoom-out">
                <div className="whyEdnovate__thumb">
                  <Media
                    type={IMAGE}
                    mediaURL={data?.[0]?.thumbnail}
                    hasBaseURL={false}
                  />
                </div>
              </div>
              <div className="whyEdnovate__grid-rhs" data-aos="zoom-out">
                <div className="section__contentBox">
                  <SectionHeading
                    customCls={"section__heading"}
                    tagName={"h2"}
                    title={"Why Ednovate"}
                  />
                  <SectionCaption caption={data?.[0]?.description} />
                </div>
                <div className="whyEdnovate__list">
                  <ul>
                    {data?.[0]?.highlights?.map((item, index) => (
                      <li
                        key={index}
                        data-aos="fade-left"
                        data-aos-delay={100 * index}
                      >
                        <i className="edi-right-tick"></i>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyEdnovate;

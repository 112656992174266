import React, { useCallback, useEffect } from "react";
import { Tabs } from "../tabs";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";
import { useGetRevisionBatchesQuery } from "../../api/services/mahaRevision";

const RevisionType = () => {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state?.app?.home);
  const { data } = useGetRevisionBatchesQuery(homeState?.activeClassType);

  const setActiveTab = useCallback(
    (tab) => {
      dispatch(home.setActiveRevisionType(tab));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data?.length) {
      setActiveTab(data?.[0]?.id);
    } else {
      setActiveTab(undefined);
    }
  }, [setActiveTab, data]);

  return (
    <>
      <div className="subjectType__wrap">
        <Tabs
          customCls={"tabs__wrap--tertiary chapterWise"}
          active={homeState?.activeRevisionType}
          data={data || []}
          handleClick={(tab) => setActiveTab(tab)}
          type="object"
          displayKey="title"
        />
      </div>
    </>
  );
};

export default RevisionType;

import React from "react";

import {
  useGetBranchZonesQuery,
  useGetOutsideZoneBranchsQuery,
  useGetRailwayZonesQuery,
  useGetZoneBranchesQuery,
} from "../../api/services/home";
import { CtaButton } from "../ctaButton";
import { home } from "../../redux/actions";
import { useDispatch } from "react-redux";

const BranchList = () => {
  const dispatch = useDispatch();

  function toggleBranchOverlay(val = false, branch = {}) {
    dispatch(
      home.setShowBranchOverlay({
        isBranchOverlayOpen: val,
        selectedBranch: branch,
      })
    );
  }

  const { data: branchTypes } = useGetBranchZonesQuery();

  // const { data: branches } = useGetBranchByStatesQuery("");
  const { data: inMumbaiZone1Branches } = useGetZoneBranchesQuery(1);
  const { data: inMumbaiZone2Branches } = useGetZoneBranchesQuery(2);
  const { data: inMumbaiZone3Branches } = useGetZoneBranchesQuery(3);

  const inMumbaiBranches = {
    inMumbaiZone1Branches,
    inMumbaiZone2Branches,
    inMumbaiZone3Branches,
  };

  const { data: inMumbaiZones = [] } = useGetRailwayZonesQuery();
  const { data: outsideMumbaiBranches = [] } = useGetOutsideZoneBranchsQuery();

  return (
    <>
      {branchTypes?.map((zone) => (
        <>
          <div className="branchZone" key={"branch-zone-" + zone?.id}>
            <h2 className="branchZone__heading">{zone.zone}</h2>

            {zone?.id === 1 && (
              <div className="branchZone__grid">
                {inMumbaiZones?.map((zone) => (
                  <div className="branchZone__grid-item">
                    <>
                      <h3
                        className="branchZone__title"
                        key={"mumbai-zone-" + zone?.id}
                      >
                        {zone.zone}
                      </h3>
                      <div className="branchZone__list">
                        {inMumbaiBranches[
                          "inMumbaiZone" + zone?.id + "Branches"
                        ]?.map((branch) => (
                          <CtaButton
                            key={"branch-" + branch?.id}
                            btnCls={
                              "btn btn__round btn__stroke btn__sm btn__tertiary btn__tertiary--outline"
                            }
                            btnText={branch.branch_name || branch.branchname}
                            onClickProp={() => {
                              toggleBranchOverlay(true, branch);
                            }}
                          />
                        ))}
                      </div>
                    </>
                  </div>
                ))}
              </div>
            )}
            {zone?.id === 2 && (
              <div className="branchZone__list">
                {outsideMumbaiBranches?.map((branch) => (
                  <CtaButton
                    key={"branch-" + branch?.id}
                    btnCls={
                      "btn btn__round btn__stroke btn__sm btn__tertiary btn__tertiary--outline"
                    }
                    btnText={branch.branch_name || branch.branchname}
                    onClickProp={() => {
                      toggleBranchOverlay(true, branch);
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </>
      ))}

      {/* <div className="branchList">
                            {branches?.map((branch) => (
                              <CtaButton
                                key={"branch-" + branch?.id}
                                btnCls={
                                  "btn btn__primary btn__primary--outline"
                                }
                                btnText={
                                  branch.branch_name || branch.branchname
                                }
                                onClickProp={() => {
                                  toggleBranchOverlay(true, branch);
                                }}
                              />
                            ))}
                          </div> */}
    </>
  );
};

export default BranchList;

import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import { CtaButton } from "../ctaButton";
import EdnovateStatsList from "../ednovateStatsList";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { useGetLearnWithEdnovateQuery } from "../../api/services/home";
import { useNavigate } from "react-router";
import { getPath, toKebabCase } from "../../utils";
import { useSelector } from "react-redux";

const StatsSection = () => {
  const navigate = useNavigate();
  // const statsArr = [
  //   { lbl: "Views", num: 9999 },
  //   { lbl: "Classes", num: 99999 },
  //   { lbl: "Daily Live Classes", num: 999999 },
  //   { lbl: "Ranks", num: 999999 },
  //   { lbl: "Views", num: 9999999 },
  // ];

  const { data, isLoading } = useGetLearnWithEdnovateQuery();
  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <section
        className="section__row  section--statsSection"
        data-aos="fade-up"
      >
        <div className="main__container">
          <div className="statsSection__grid">
            <div className="statsSection__grid-lhs">
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h2"}
                  title={data?.[0]?.title}
                />
                <SectionCaption caption={`<p>${data?.[0]?.description}</p>`} />
              </div>
              <div className="statsSection__cta">
                <CtaButton
                  btnCls={"btn btn__primary"}
                  iconCls={"edi-next"}
                  btnText={data?.[0]?.button_text}
                  onClickProp={() => {
                    navigate(
                      getPath("courses", {
                        batch: toKebabCase(masterData?.batch?.[0]?.batch),
                        course: toKebabCase(
                          masterData?.courses?.find(
                            (x) => x.batch_id === masterData?.batch?.[0]?.id
                          )?.title
                        ),
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div className="statsSection__grid-rhs">
              <div className="statsSection__thumb">
                <Media
                  type={IMAGE}
                  mediaURL={data?.[0]?.thumbnail}
                  hasBaseURL={false}
                />
              </div>
              <div className="statsSection__data">
                <EdnovateStatsList
                  lbl={data?.[0]?.text_box1}
                  nums={data?.[0]?.count1}
                />
                <EdnovateStatsList
                  lbl={data?.[0]?.text_box2}
                  nums={data?.[0]?.count2}
                />
                <EdnovateStatsList
                  lbl={data?.[0]?.text_box3}
                  nums={data?.[0]?.count3}
                />
                <EdnovateStatsList
                  lbl={data?.[0]?.text_box4}
                  nums={data?.[0]?.count4}
                />
                <EdnovateStatsList
                  lbl={data?.[0]?.text_box5}
                  nums={data?.[0]?.count5}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StatsSection;

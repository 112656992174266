import React, { useRef } from "react";
import OverlayFooter from "./overlayFooter";
import OverlayHeader from "./overlayHeader";
import OverlayClose from "./overlayClose";

function Overlays({ overlayName, overlayConfig, children }) {
  const {
    noOverlayClose = true,
    noOverlayHead = true,
    noOverlayBody = true,
    noOverlayFoot = true,
    onClickProp,
  } = overlayConfig;

  return (
    <>
      <div
        className={`popup__wrap ${
          overlayName ? `popup__wrap--${overlayName}` : ""
        } fade in`}
      >
        {/* in | out */}
        <div className="popup__container">
          {noOverlayClose && <OverlayClose onClickProp={onClickProp} />}
          {noOverlayHead && <OverlayHeader {...overlayConfig} />}
          {noOverlayBody && <div className="popup__body">{children}</div>}
          {noOverlayFoot && <OverlayFooter {...overlayConfig} />}
        </div>
      </div>
    </>
  );
}

export { Overlays };

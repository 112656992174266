import React from "react";
import Anchor from "../anchor";
import { isMobile } from "../../common/config";

const TalkToUs = () => {
  return (
    <>
      <Anchor
        linkCls={"talkUs"}
        hyperLink={"tel:+917277367367"}
        component={
          <>
            {!isMobile() && (
              <div className="talkUs-text">
                <span>Talk to Us</span>
                <span>+91 72773 67367</span>
              </div>
            )}
            <div className="talkUs-icon">
              <i className="edi-phone"></i>
            </div>
          </>
        }
      />
    </>
  );
};

export default TalkToUs;

import React, { useEffect, useState } from "react";
import { CtaButton } from "../ctaButton";
import { Overlays } from "../overlays";
import AnnouncementCard from "../announcementCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGetAnnouncementsQuery } from "../../api/services/home";
import { IMAGE } from "../../common/config";

const Announcement = () => {
  const ANNOUNCEMENT_COUNT_LOCAL_STORAGE_KEY = "announcements_read";
  useEffect(() => {
    function initAnnouncement() {
      gsap.registerPlugin(ScrollTrigger);
      const showAnim = gsap
        .from(".announcement__cta", {
          xPercent: 100,
          rotate: 0,
          paused: true,
          duration: 0.2,
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse();
        },
      });
    }

    initAnnouncement();
  }, []);

  const [showOverlay, setShowOverlay] = useState(false);

  const { data } = useGetAnnouncementsQuery();
  const isUpdate =
    Number(localStorage.getItem(ANNOUNCEMENT_COUNT_LOCAL_STORAGE_KEY)) !==
    data?.length;

  useEffect(() => {
    if (data?.length) {
      setShowOverlay(isUpdate);
    }
  }, [data, isUpdate]);

  return (
    <>
      <div className="announcement__cta">
        <CtaButton
          btnCls={`btn btn__announcement ${isUpdate ? "active" : ""}`}
          btnText={"Announcement"}
          onClickProp={() => {
            localStorage.setItem(
              ANNOUNCEMENT_COUNT_LOCAL_STORAGE_KEY,
              data?.length
            );
            setShowOverlay((value) => !value);
          }}
        />
      </div>

      {showOverlay && (
        <Overlays
          overlayName={"announcement"}
          overlayConfig={{
            noOverlayClose: true,
            noOverlayHead: true,
            noOverlayBody: true,
            noOverlayFoot: false,
            onClickProp: () => {
              localStorage.setItem(
                ANNOUNCEMENT_COUNT_LOCAL_STORAGE_KEY,
                data?.length
              );
              setShowOverlay((value) => !value);
            },
            title: "Announcement",
          }}
        >
          <div className="announcement__list">
            {data?.map((item) => (
              <AnnouncementCard
                key={item?.id}
                title={item?.title}
                caption={item?.description}
                type={IMAGE}
                url={item?.thumbnail}
                props={{ hasBaseURL: false }}
                published={item?.announcement_date}
                link={item?.redirect_link}
              />
            ))}
          </div>
        </Overlays>
      )}
    </>
  );
};

export default Announcement;

import React from "react";

const Tabs = ({
  data,
  active,
  handleClick = () => {},
  showIconText = "",
  showIcon = false,
  iconClass = "",
  type = "text",
  disabledData = "",
  customCls,
  displayKey = "text",
}) => {
  const activeClass = (name) => {
    return name === active ? "active" : "";
  };
  return (
    <>
      <div className={`tabs__wrap ${customCls ? customCls : ""}`}>
        {type === "text" ? (
          <ul>
            {data.map((name, index) => (
              <li key={index}>
                <button
                  className={`${activeClass(name)} ${
                    !Array.isArray(disabledData)
                      ? disabledData === name
                        ? "disabled"
                        : ""
                      : disabledData.includes(name)
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => handleClick(name)}
                  aria-label={name}
                  disabled={
                    !Array.isArray(disabledData)
                      ? disabledData === name
                      : disabledData.includes(name)
                      ? true
                      : false
                  }
                >
                  {name}
                  {showIconText === name && showIcon && (
                    <i className={iconClass}></i>
                  )}
                </button>
              </li>
            ))}
          </ul>
        ) : type === "object" ? (
          <ul>
            {data.map((item, index) => (
              <li key={index}>
                <button
                  className={activeClass(item.id)}
                  onClick={() => handleClick(item.id)}
                  aria-label={item?.[displayKey]}
                  dangerouslySetInnerHTML={{
                    __html: item?.[displayKey],
                  }}
                >
                  {/* {item?.[displayKey]}
                  {showIconText === item.id && showIcon && (
                    <i className={iconClass}></i>
                  )} */}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { Tabs };

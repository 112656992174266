import React, { useCallback, useEffect, useState } from "react";
import { Tabs } from "../tabs";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";

const BranchStates = ({ tabList }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(tabList);
  const homeState = useSelector((state) => state?.app?.home);

  const setActiveTab = useCallback(
    (tab) => dispatch(home.setActiveBranchState(tab)),
    [dispatch]
  );

  useEffect(() => {
    if (tabList?.length) {
      setData(tabList);
      setActiveTab(tabList[0]?.id);
    }
  }, [tabList, setActiveTab]);

  return (
    <>
      <div className="learningType__wrap">
        <Tabs
          customCls={"tabs__wrap--gradient tabs__wrap--fdcol"}
          active={homeState?.activeBranchState}
          data={data}
          handleClick={(tab) => setActiveTab(tab)}
          type="object"
          displayKey="state"
        />
      </div>
    </>
  );
};

export default BranchStates;

import React from "react";
import OverlayFooter from "../overlayFooter";

const OverlayHeader = ({ title, caption, overlayHeaderCtaConfig = {} }) => {
  const { noOverlayHeaderCtaFoot = false } = overlayHeaderCtaConfig;
  return (
    <>
      <div className="popup__head">
        {title && (
          <>
            <div className="popup__heading">
              <span>{title}</span>
            </div>
          </>
        )}
        {caption && (
          <div className="popup__heading-sub">
            <span>{caption}</span>
          </div>
        )}
        {noOverlayHeaderCtaFoot && (
          <OverlayFooter {...overlayHeaderCtaConfig} />
        )}
      </div>
    </>
  );
};

export default OverlayHeader;

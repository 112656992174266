import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import {
  IMAGE,
  IMAGE_VERSION,
  IMG_BASE,
  VIDEO,
  YOUTUBE_VIDEO,
} from "../../common/config";
import DiscoverNowButton from "../discoverNowButton";
import Media from "../media";
import { useRerenderAnimation } from "../../hooks";
import { CtaButton } from "../ctaButton";
import { htmlDecode } from "../../utils";
import HomeBannerOverlay from "../homeBannerOverlay";
const HeroBanner = ({ props, bannerConfig }) => {
  const { isCertificate } = bannerConfig;
  const { show: showSlide, handler: handleSlideChange } =
    useRerenderAnimation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});

  function toggleOverlay(show = false, data = {}) {
    setShowOverlay(show);
    setSelectedMedia(data);
  }

  return (
    <>
      <div className="heroBanner__swiper">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation={{
            prevEl: ".heroBanner__swiper .swiper__nav-prev",
            nextEl: ".heroBanner__swiper .swiper__nav-next",
          }}
          grabCursor={true}
          autoplay={{
            enabled: true,
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          onSlideChange={handleSlideChange}
          className="mySwiper__heroBanner"
        >
          {props?.map((item) => (
            <SwiperSlide key={"hero-banner-" + item?.id}>
              <div className="main__container">
                <div className="heroBanner__grid">
                  {isCertificate && (
                    <div className="certifcate__stamp">
                      <img
                        src={`${IMG_BASE}certificate-round-stamp.svg?v=${IMAGE_VERSION}`}
                        alt="100 certificate guarantee stamp thumbnail"
                      />
                    </div>
                  )}
                  <div className="heroBanner__grid-lhs">
                    <div className="section__contentBox">
                      <SectionHeading tagName="h2" title={item?.title} />
                      <SectionCaption caption={htmlDecode(item?.description)} />
                      <div className="heroBanner__cta">
                        <DiscoverNowButton
                          btnText={item?.button_text}
                          onClickProp={() => {
                            window.location.href = item?.redirect_link;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="heroBanner__grid-rhs">
                    <div
                      className="heroBanner__thumbnail"
                      onClick={() =>
                        (item?.type === VIDEO ||
                          item?.type === YOUTUBE_VIDEO) &&
                        toggleOverlay(true, item)
                      }
                    >
                      <Media
                        type={IMAGE}
                        mediaURL={item?.image_link}
                        hasBaseURL={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper__nav">
          <CtaButton btnCls="swiper__nav-prev" iconCls={"edi-previous"} />
          <CtaButton btnCls="swiper__nav-next" iconCls={"edi-next"} />
        </div>
      </div>
      <HomeBannerOverlay
        show={showOverlay}
        toggleOverlay={toggleOverlay}
        {...selectedMedia}
      />
    </>
  );
};

export default HeroBanner;

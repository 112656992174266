import React from "react";
import { useNavigate } from "react-router-dom";
import { CtaButton } from "../ctaButton";

const BackButton = (props) => {
  const {
    callBackFunction = () => {},
    hasCustomBackAction,
    onBack = () => {},
  } = props;
  const navigate = useNavigate();
  const handleBack = () => {
    onBack();
    navigate(-1);
  };
  return (
    <div className="backBtn">
      <CtaButton
        btnCls="btn btn__icon btn__round btn__back"
        iconCls="edi-previous"
        btnText=""
        ariaLabel={"Click to go back"}
        onClickProp={() =>
          !hasCustomBackAction ? handleBack() : callBackFunction()
        }
      />
    </div>
  );
};

export default BackButton;

import React from "react";
import Media from "../media";
import { IMAGE } from "../../common/config";

const ReviewCard = ({
  userThumbnail,
  userName,
  userSkill,
  userRating,
  userdescription,
}) => {
  return (
    <>
      <article className="reviewCard">
        <div className="reviewCard-head">
          <div className="course__card-proposer">
            <figure>
              <Media
                type={IMAGE}
                mediaURL={
                  userThumbnail ? userThumbnail : "user-placeholder.svg"
                }
                hasBaseURL={false}
              />
              <figcaption>
                {userName} <span>{userSkill}</span>
              </figcaption>
            </figure>
          </div>
          <div className="course__card-rating">
            <i className="edi-star-outline"></i>
            <span>{userRating}</span>
          </div>
        </div>
        <div
          className="reviewCard-body"
          dangerouslySetInnerHTML={{ __html: userdescription }}
        ></div>
      </article>
    </>
  );
};

export default ReviewCard;

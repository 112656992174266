import React, { useEffect, useState } from "react";
// import SectionHeading from "../sectionHeading";
import { IMAGE, YOUTUBE_VIDEO } from "../../common/config";
import DemoLectureVideoPlayer from "../demoLectureVideoPlayer";
import { CtaButton } from "../ctaButton";
import {
  useGetMahaRevisionQuery,
  useGetRevisionBatchesQuery,
  useGetSubjectByClassQuery,
} from "../../api/services/mahaRevision";
import { useSelector } from "react-redux";
import Media from "../media";
import { useLocation } from "react-router";

const MahaRevisionLectures = () => {
  const location = useLocation();
  const homeState = useSelector((state) => state?.app?.home);
  const { data = [], isSuccess } = useGetMahaRevisionQuery({
    revision_id: homeState?.activeRevisionType,
    class_id: homeState?.activeClassType,
  });
  const [selectedLecture, setSelectedLecture] = useState(data?.[0]);
  const { data: subjects } = useGetSubjectByClassQuery(
    homeState?.activeClassType
  );
  const { data: revisionBatches } = useGetRevisionBatchesQuery(
    homeState?.activeClassType
  );

  const [showThumbnail, setShowThumbnail] = useState(true);
  useEffect(() => {
    setSelectedLecture(data?.[0]);
    setShowThumbnail(true);
  }, [homeState?.activeClassType, homeState?.activeSubjectType, data]);

  useEffect(() => {
    if (isSuccess && location.hash === "#videos") {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isSuccess, location.hash]);

  return (
    <>
      <section
        className="section__row  section--demoLectures"
        data-aos="fade-up"
      >
        {/* <div className="main__container">
          <div className="demoLecture__grid">
            <div className="demoLecture__grid-lhs">
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h2"}
                  title={"Attempt Year"}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="main__container">
          <div className="demoLecture__player">
            {showThumbnail ? (
              <div className="demoLecture__playerCoverThumb">
                <Media
                  mediaURL={
                    revisionBatches?.find(
                      (subject) => subject?.id === homeState?.activeRevisionType
                    )?.thumbnail || selectedLecture?.thumbnail
                  }
                  type={IMAGE}
                  hasBaseURL={false}
                />
                <div className="demoLecture__playerCoverThumb-cta">
                  <CtaButton
                    btnCls={"btn btn__primary"}
                    btnText={`View Playlist`}
                    onClickProp={() => {
                      setShowThumbnail(false);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="demoLecture__player-grid">
                <div className="demoLecture__player-lhs">
                  {subjects?.length && selectedLecture ? (
                    <DemoLectureVideoPlayer
                      videoConfig={
                        (selectedLecture,
                        { mediaAttrib: { controls: true, id: "yt-player" } })
                      }
                      coverThumbConfig={{
                        mediaURL: selectedLecture?.thumbnail,
                      }}
                      type={YOUTUBE_VIDEO}
                      url={selectedLecture?.youtube_url}
                      duration={selectedLecture?.duration}
                      title={selectedLecture?.title}
                      caption={selectedLecture?.description}
                      viewed={selectedLecture?.viewed}
                      uploadedDate={selectedLecture?.uploadedDate}
                    />
                  ) : (
                    "No Videos"
                  )}
                </div>
                <div className="demoLecture__player-rhs">
                  <div className="demoLecture__player-list">
                    <ul>
                      {subjects?.length &&
                        data?.map((item, index) => (
                          <li
                            style={{ animationDelay: "." + index + "s" }}
                            className={
                              selectedLecture?.id === item.id ? "active" : ""
                            }
                            key={index}
                          >
                            <CtaButton
                              btnCls={"btn__unset"}
                              onClickProp={() => setSelectedLecture(item)}
                              components={
                                <>
                                  <DemoLectureVideoPlayer
                                    videoConfig={{
                                      mediaURL: item?.youtube_url,
                                      hasBaseURL: false,
                                    }}
                                    type={IMAGE}
                                    url={
                                      item?.thumbnail
                                        ? item?.thumbnail
                                        : "default-placeholder/video-default.png"
                                    }
                                    title={item?.title}
                                    caption={item?.description}
                                    uploadedDate={item?.uploadedDate}
                                  />
                                </>
                              }
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default MahaRevisionLectures;

import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { useGetUltimateLearningQuery } from "../../api/services/courses";

const EnpowerYourLearning = () => {
  // const featuredArr = [
  //   "Feature USP 1",
  //   "Feature USP 2",
  //   "Feature USP 3",
  //   "Feature USP 4",
  // ];

  const { data } = useGetUltimateLearningQuery();

  return (
    <>
      <section className="section__row section--enpowerYourLearning">
        <div className="main__container">
          <div className="enpowerYourLearning__grid">
            <div
              className="enpowerYourLearning__grid-lhs"
              data-aos="fade-right"
            >
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h2"}
                  title={data?.[0]?.title}
                />
                <SectionCaption caption={data?.[0]?.description} />
              </div>
              <div className="enpowerYourLearning__list">
                <ul>
                  {data?.[0]?.highlights.map((item, index) => (
                    <li
                      key={index}
                      data-aos="fade-right"
                      data-aos-delay={100 * index}
                    >
                      <i className="edi-angle-double-right"></i>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="enpowerYourLearning__grid-rhs" data-aos="fade-left">
              <div className="enpowerYourLearning__thumb">
                <Media
                  type={IMAGE}
                  mediaURL={data?.[0]?.thumbnail}
                  hasBaseURL={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnpowerYourLearning;

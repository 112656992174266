import React from "react";
// import { getNumericNotation } from "../../common/config";

const EdnovateStatsList = ({ customCls, lbl, nums }) => {
  return (
    <>
      <div className={`statsSection__box ${customCls ? customCls : ""}`}>
        <div className="statsSection__box-lbl">{lbl}</div>
        <div className="statsSection__box-nums">{nums}</div>
      </div>
    </>
  );
};

export default EdnovateStatsList;

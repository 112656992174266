import React, { useState } from "react";
import { Overlays } from "../overlays";
import Accordion from "../accordion";
import AccordionChild from "../accordion/accordionChild";
import InputCheckbox from "../forms/inputCheckbox";

const accordianHeaders = ["Subjects", "Chapters"];

const FilterOverlay = ({
  handleApply = () => {},
  data,
  selSubjects,
  selChapters,
}) => {
  const [activeIndex, setActiveIndex] = useState([0]);
  const [selectedSubjects, setSelectedSubjects] = useState(selSubjects);
  const [selectedChapters, setSelectedChapters] = useState(selChapters);

  const subjects = data?.map((x) => ({
    subject_id: x.id,
    subject_name: x.name,
  }));
  const chapters = data
    ?.filter((subject) => selectedSubjects?.includes(subject?.id))
    ?.map((x) => [...x.chapters])
    ?.flat();

  function updateActiveIndex(index) {
    setActiveIndex((val) => {
      if (val.includes(index)) {
        val.splice(
          val.findIndex((x) => x === index),
          1
        );
        return [...val];
      } else {
        return [...val, index].sort();
      }
    });
  }

  return (
    <>
      <Overlays
        overlayName={"filter"}
        overlayConfig={{
          title: "Filter",
          cancelBtn: true,
          submitBtn: true,
          btnPrimary: "Apply",
          btnPrimaryIcon: "edi-next",
          btnSecondary: "Reset",
          btnSecondaryIcon: "edi-loadmore",
          noOverlayHead: true,
          noOverlayFoot: true,
          onClickProp: () => handleApply(selectedSubjects, selectedChapters),
          onConfirmClick: () => handleApply(selectedSubjects, selectedChapters),
          onCancelClick: () => {
            setSelectedSubjects([]);
            setSelectedChapters([]);
          },
        }}
      >
        <Accordion
          data={accordianHeaders}
          activeIndex={activeIndex}
          accordionChild={accordianHeaders.map((item, index) => (
            <AccordionChild
              index={index}
              isActive={activeIndex.includes(index)}
              key={index}
              updateActiveIndex={updateActiveIndex}
              accordionHead={
                <>
                  <h2 className="accordion__header">{item}</h2>
                </>
              }
              bodyComponent={
                index === 0 ? (
                  subjects?.map((subject) => (
                    <>
                      <InputCheckbox
                        key={"subject-" + subject?.subject_id}
                        checkboxID={subject?.subject_id}
                        checked={selectedSubjects.includes(subject?.subject_id)}
                        label={subject?.subject_name}
                        onChange={(e) => {
                          let selectedID = e.target.value;
                          setSelectedSubjects((val) => {
                            if (val.includes(selectedID)) {
                              val.splice(
                                val.findIndex((x) => x === selectedID),
                                1
                              );
                              return [...val];
                            } else {
                              return [...val, selectedID].sort();
                            }
                          });
                        }}
                      />
                    </>
                  ))
                ) : index === 1 ? (
                  chapters?.map((chapters) => (
                    <>
                      <InputCheckbox
                        key={"chapter-" + chapters?.chapter_id}
                        checkboxID={chapters?.chapter_id}
                        checked={selectedChapters?.includes(
                          chapters?.chapter_id
                        )}
                        label={chapters?.chapter_name}
                        onChange={(e) => {
                          let selectedID = e.target.value;
                          setSelectedChapters((val) => {
                            if (val.includes(selectedID)) {
                              val.splice(
                                val.findIndex((x) => x === selectedID),
                                1
                              );
                              return [...val];
                            } else {
                              return [...val, selectedID].sort();
                            }
                          });
                        }}
                      />
                    </>
                  ))
                ) : (
                  <>
                    <InputCheckbox
                      checkboxID={item}
                      label={item}
                      onChange={() => {}}
                    />
                  </>
                )
              }
              accordionConfig={{}}
            />
          ))}
        />
      </Overlays>
    </>
  );
};

export default FilterOverlay;

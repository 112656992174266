import React, { useRef } from "react";
import InputLabel from "../inputLbl";

const Textarea = ({
  parentExtraCls,
  inputLbl,
  inputID,
  inputType = "text",
  inputTooltipText,
  placeholderLabel,
  isDisabled = false,
  inputText,
  handleInput,
  extraAttri = {},
  inputValidate,
  registerFunction,
  onBlur = () => {},
}) => {
  const parentDiv = useRef(null);

  function handleBlur(e) {
    onBlur(e);
  }
  const { ref = null, ...rest } = registerFunction
    ? registerFunction(inputID)
    : {};

  return (
    <>
      <div
        ref={parentDiv}
        className={`input ${parentExtraCls ? parentExtraCls : ""} ${
          inputValidate ? "invalid" : ""
        }`}
      >
        {inputLbl ? (
          <InputLabel
            inputLbl={inputLbl}
            inputID={inputID}
            inputTooltipText={inputTooltipText}
          />
        ) : (
          ""
        )}

        <div className="input__field">
          <textarea
            autoComplete="off"
            name={inputID}
            type={inputType}
            id={inputID}
            placeholder={placeholderLabel}
            disabled={isDisabled}
            value={inputText}
            onChange={handleInput}
            onFocus={() => {}}
            onBlur={handleBlur}
            {...extraAttri}
            ref={ref}
            {...rest}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export { Textarea };

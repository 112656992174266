import React from "react";

const Notes = ({ data }) => {
  return (
    <>
      <div className="notes">
        <ul>
          {data.map((item, index) => (
            <li key={index} data-aos="zoom-in" data-aos-delay={index * 100}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Notes;

import React from "react";
import { InputField } from "../inputField";
import ValidationText from "../validationText";

const InputSearchBox = ({
  srchLbl,
  srchID,
  srchPlaceholder,
  handleInput,
  inputValue,
  clearInputValue,
  searchConfig,
}) => {
  return (
    <>
      <InputField
        inputLbl={srchLbl ? srchLbl : ""}
        inputID={srchID}
        inputType={"text"}
        placeholderLabel={srchPlaceholder}
        components={false && <ValidationText validationText={"requied"} />}
        inputValidate={false}
        isIcon={inputValue ? "edi-close" : "edi-search"}
        handleInput={handleInput}
        iconClickHandler={clearInputValue}
        inputText={inputValue}
        {...searchConfig}
      />
    </>
  );
};

export default InputSearchBox;

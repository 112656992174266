import React from "react";
import Tooltip from "../../tooltip";

const InputLabel = ({
  inputID,
  inputLbl,
  inputTooltipText,
  inputTooltipIcon,
}) => {
  return (
    <>
      <label className="lbl" htmlFor={inputID}>
        {inputLbl}
        {inputTooltipText && (
          <Tooltip
            tooltipIcon={inputTooltipIcon}
            tooltipText={inputTooltipText}
          />
        )}
      </label>
    </>
  );
};

export default InputLabel;

import React, { useEffect } from "react";

const Countdown = ({ seconds, timeLeft, setTimeLeft }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [setTimeLeft]);

  return <div>{timeLeft}</div>;
};

export default Countdown;

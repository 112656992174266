import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import { CtaButton } from "../ctaButton";
import Media from "../media";
import { isMobile } from "../../common/config";
const HeroBannerInnerSection = ({ customCls, data, swiperProps, ctaProps }) => {
  return (
    <>
      <section
        className={`section--heroBannerInner ${customCls ? customCls : ""}`}
      >
        <Swiper
          modules={[Autoplay, Navigation]}
          speed={1000}
          loop={data?.length > 1}
          autoplay={{
            enabled: true,
            delay: 4000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          className="mySwiperHeroBannerInner"
          navigation={{
            prevEl: ".section--heroBannerInner .swiper__nav-prev",
            nextEl: ".section--heroBannerInner .swiper__nav-next",
          }}
          {...swiperProps}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="heroBannerInner__box">
                <div className="heroBannerInner__thumb">
                  <Media
                    {...item}
                    hasBaseURL={false}
                    mediaURL={
                      isMobile()
                        ? item?.mobile_image || item?.image_link
                        : item?.image_link
                    }
                  />
                </div>
                <div className="heroBannerInner__content">
                  <div className="main__container">
                    <div className="section__contentBox">
                      <SectionHeading
                        customCls={"section__heading"}
                        tagName={"h1"}
                        title={item.title}
                      />
                      <SectionCaption caption={item.description} />
                      {ctaProps && (
                        <div className="heroBannerInner__cta">
                          <CtaButton {...item} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper__nav">
          <CtaButton btnCls="swiper__nav-prev" iconCls={"edi-previous"} />
          <CtaButton btnCls="swiper__nav-next" iconCls={"edi-next"} />
        </div>
      </section>
    </>
  );
};

export default HeroBannerInnerSection;

import React from "react";
import Media from "../media";
import { IMAGE } from "../../common/config";
import HexagonClipMask from "../hexagonClipMask";

const TopRankerCard = ({ id, thumb, name, rank, isHex, title }) => {
  return (
    <>
      <div className="rankerCard">
        <div className="rankerCard__thumb">
          {isHex ? (
            <HexagonClipMask id={id} thumb={thumb} />
          ) : (
            <Media type={IMAGE} mediaURL={thumb} hasBaseURL={false} />
          )}
        </div>
        <div className="rankerCard__title">
          <span>{name}</span>
        </div>
        <div className="rankerCard__badge">
          <em>{title}</em>
          {rank}
        </div>
      </div>
    </>
  );
};

export default TopRankerCard;

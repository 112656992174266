import React from "react";

const InputRadio = ({
  extraCls,
  siblings,
  radioID,
  label,
  onChange,
  components,
  radioState,
  value,
  inputValidate,
  registerFunction,
  checked,
  disable,
}) => {
  const { ref = null, ...rest } = registerFunction
    ? registerFunction(siblings)
    : {};
  return (
    <>
      <div className={`radioBtn ${extraCls ? extraCls : ""}`}>
        <input
          type="radio"
          id={radioID}
          name={siblings}
          value={value}
          disabled={disable}
          checked={checked}
          ref={ref}
          {...rest}
          onChange={onChange}
        />
        {inputValidate ? components : ""}
        <label htmlFor={radioID}>
          {label && <div className="radioBtn__lbl"> {label}</div>}
          {components}
        </label>
      </div>
    </>
  );
};

export default InputRadio;

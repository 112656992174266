import React from "react";

const Loader = ({ loaderText }) => {
  return (
    <>
      <div className="pageLoader__wrap">
        <div className="pageLoader__box">
          <div className="pageLoader__spinner"></div>
          {loaderText && <div className="pageLoader__text">{loaderText}</div>}
        </div>
      </div>
    </>
  );
};

export default Loader;

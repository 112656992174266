import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import Media from "../media";
import Anchor from "../anchor";
import { IMAGE } from "../../common/config";

const DownloadApp = () => {
  return (
    <>
      <section className="section__row  section--downloadApp">
        <div className="main__container">
          <div className="downloadApp__grid">
            <div className="downloadApp__grid-lhs" data-aos="fade-left">
              <div className="downloadApp__thumb">
                <Media type={IMAGE} mediaURL={"download-app-thumbnail.png"} />
              </div>
            </div>
            <div className="downloadApp__grid-rhs" data-aos="fade-right">
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h2"}
                  title={"Ednovate App: <br/> Your Ultimate Study Companion"}
                />
                <SectionCaption
                  caption={`<p>Elevate your learning with the Ednovate app, designed for our students. Access materials, take quizzes, and manage your study schedule easily, all from your mobile device. Stay on top of deadlines and exams with personalized reminders.</p>`}
                />
              </div>
              <div className="downloadApp__cta">
                <div className="downloadApp__cta-lbl">
                  <span>Download Now!</span>
                </div>
                <div className="downloadApp__cta-list">
                  <Anchor
                    hyperLink={
                      "https://apps.apple.com/in/app/ednovate/id1638056761"
                    }
                    component={
                      <>
                        <Media
                          type={IMAGE}
                          mediaURL={"download-on-the-app-store-apple.svg"}
                        />
                      </>
                    }
                  />
                  <Anchor
                    hyperLink={
                      "https://play.google.com/store/apps/details?id=com.ednovate.studentapp&hl=en-IN"
                    }
                    component={
                      <>
                        <Media
                          type={IMAGE}
                          mediaURL={"google-play-badge.svg"}
                        />
                      </>
                    }
                  />
                  <Anchor
                    hyperLink={"#"}
                    component={
                      <>
                        <Media
                          type={IMAGE}
                          mediaURL={"download-windows-app.svg"}
                        />
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DownloadApp;

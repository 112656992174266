import React from "react";
import TestifyDetailCard from "../testifyDetailCard";
import { useGetOurCEOQuery } from "../../api/services/aboutus";

const MeetOurFounder = () => {
  const { data } = useGetOurCEOQuery();

  return (
    <>
      <section className="section__row  section--meetOurFounder">
        <div className="main__container">
          <TestifyDetailCard
            thumbnail={data?.thumbnail}
            heading="Meet Our CEO"
            title={data?.title}
            description={data?.description}
          />
        </div>
      </section>
    </>
  );
};

export default MeetOurFounder;

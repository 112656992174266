import React from "react";
import { Link } from "react-router-dom";
import { getPath } from "../../utils";

const Cart = ({ badgeCount }) => {
  return (
    <>
      <div className={`cart__wrap `}>
        <Link
          to={getPath("cart")}
          className={`btn btn__icon ${badgeCount ? "badgeCount" : ""}`}
          data-count={badgeCount}
        >
          <i className="edi-cart"></i>
        </Link>
      </div>
    </>
  );
};

export default Cart;

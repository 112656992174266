import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import routesPath from "../../routes/routes";
import { useCurrentRoute } from "../../hooks";

const EnrolFloating = () => {
  const currentRoute = useCurrentRoute();

  useEffect(() => {
    function initAnnouncement() {
      gsap.registerPlugin(ScrollTrigger);
      const showAnim = gsap
        .from(".enrol__cta", {
          xPercent: 100,
          rotate: 0,
          paused: true,
          duration: 0.2,
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse();
        },
      });
    }

    initAnnouncement();
  }, []);

  const style = currentRoute.id === "enroll" ? { display: "none" } : {};

  return (
    <>
      <div className="enrol__cta" style={style}>
        <Link className="btn btn__enrol" to={routesPath.enroll.path}>
          <span>Enrol Now </span>
        </Link>
      </div>
    </>
  );
};

export default EnrolFloating;

import React, { useEffect } from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import { CtaButton } from "../ctaButton";
import {
  IMAGE,
  isMobile,
  LOCALSTORAGE_CART_ITEMS_KEY,
} from "../../common/config";
import { Link, useNavigate } from "react-router-dom";
import Media from "../media";
import { useAddCartMutation } from "../../api/services/cart";
import { getPath, getStudentID, isLoggedIn } from "../../utils";
// import { Tabs } from "../tabs";
const planLblArr = [
  { lbl: "No. of views", key: "views" },
  { lbl: "Validity", key: "validity" },
  { lbl: "Printed  Books", key: "books" },
  { lbl: "Test Series", key: "test_series" },
  { lbl: "Language", key: "language" },
  // { lbl: "Secure Data Storage", key: "secureDataStorage" },
  // { lbl: "Email Support", key: "emailSupport" },
  // { lbl: "24/7 Customer Support", key: "customerSupport" },
  // { lbl: "Analytics and Reporting", key: "analyticsReporting" },
  { lbl: "No. of Lectures", key: "no_of_lec" },
  { lbl: "No. of Hours", key: "no_of_hrs" },
  { lbl: "Fast Forward", key: "fast_forword" },
  { lbl: "Classes Run on", key: "class_run" },
  { lbl: "Doubt Clearing Session", key: "doubt_session" },
];

// const plans = [
//   {
//     type: "Lite",
//     icon: "icons/lite.svg",
//     cost: "8,000",
//     recommonded: false,
//     noOfViews: "1.5",
//     validity: "4 months",
//     printedBooks: false,
//     testSeries: false,
//     language: "English",
//     secureDataStorage: false,
//     emailSupport: false,
//     customerSupport: false,
//     analyticsReporting: false,
//   },
//   {
//     type: "Plus",
//     icon: "icons/plus.svg",
//     cost: "10,000",
//     recommonded: true,
//     noOfViews: "1.7",
//     validity: "8 months",
//     printedBooks: true,
//     testSeries: true,
//     language: "English",
//     secureDataStorage: true,
//     emailSupport: false,
//     customerSupport: false,
//     analyticsReporting: true,
//   },
//   {
//     type: "Enterprise",
//     icon: "icons/enterprise.svg",
//     cost: "14,000",
//     recommonded: false,
//     noOfViews: "2",
//     validity: "1 year",
//     printedBooks: true,
//     testSeries: true,
//     language: "English",
//     secureDataStorage: true,
//     emailSupport: true,
//     customerSupport: true,
//     analyticsReporting: true,
//   },
// ];

const ChoosePlan = ({ planData }) => {
  const navigate = useNavigate();

  const [submitAddCart, { isLoading, isSuccess }] = useAddCartMutation();

  // function getCourseBatch(date) {
  //   const months = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   return `${months[new Date(date).getMonth()]} ${String(
  //     new Date(date).getFullYear()
  //   ).slice(2)}`;
  // }

  function addToCart(planID, feeID) {
    if (isLoggedIn()) {
      let payload = new FormData();
      payload.append("student_id", getStudentID());
      payload.append("plan_id", planID);
      payload.append("new_feesgrp_id", feeID);

      submitAddCart(payload);
    } else {
      let localCartData =
        localStorage.getItem(LOCALSTORAGE_CART_ITEMS_KEY) &&
        JSON?.parse(localStorage.getItem(LOCALSTORAGE_CART_ITEMS_KEY));
      if (localCartData) {
        localStorage.setItem(
          LOCALSTORAGE_CART_ITEMS_KEY,
          JSON.stringify([
            ...localCartData,
            { plan_id: planID, new_feesgrp_id: feeID },
          ])
        );
      } else {
        localStorage.setItem(
          LOCALSTORAGE_CART_ITEMS_KEY,
          JSON.stringify([{ plan_id: planID, new_feesgrp_id: feeID }])
        );
      }
      navigate(getPath("login"));
    }
  }

  useEffect(() => {
    if (isSuccess) {
      navigate(getPath("cart"));
    }
  }, [isSuccess, navigate]);

  return (
    <>
      <section className="section__row  section--choosePlan">
        <div className="main__container" data-aos="fade-up">
          <div className="choosePlan__container">
            <div className="section__contentBox">
              <SectionHeading
                customCls={"section__heading"}
                tagName={"h2"}
                title={"Choose Plan"}
              />
              <SectionCaption
                caption={`<p>Begin your educational voyage towards a prosperous career. Explore a variety of pecialized courses designed.</p>`}
              />
            </div>
            <div className="choosePlan__grid">
              <div className="choosePlan__grid-lhs">
                <div className={`choosePlan__box `}>
                  <div className="choosePlan__box-top">
                    <Link
                      to={getPath("contact")}
                      className="choosePlan__cta"
                      data-aos="zoom-in"
                    >
                      <div className="choosePlan__cta-grid">
                        <div className="choosePlan__cta-gridLHS">
                          <Media
                            type={IMAGE}
                            mediaURL={"icons/chooseplan-cta-bg.svg"}
                          />
                        </div>
                        <div className="choosePlan__cta-gridRHS">
                          <div className="choosePlan__cta-title">
                            Contact us!
                          </div>
                          <div className="choosePlan__cta-lbl">
                            Talk to Us <i className="edi-next"></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {!isMobile() && (
                    <div className="choosePlan__box-bot" data-aos="zoom-in">
                      <ul>
                        {planLblArr.map((item, index) => (
                          <li
                            key={index}
                            data-aos="fade-right"
                            data-aos-delay={"50" * index}
                          >
                            {item.lbl}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className="choosePlan__grid-rhs">
                {planData?.map((item, index) => (
                  <div
                    className={`choosePlan__box ${
                      item.recommend === "1" ? "recommonded" : ""
                    }`}
                    key={index}
                  >
                    <div
                      className="choosePlan__box-top"
                      data-aos="zoom-in-down"
                    >
                      {item.recommend === "1" && (
                        <div className="choosePlan__box-tag">
                          <span>Recommended</span>
                        </div>
                      )}
                      {/* <div className="choosePlan__box-icon">
                        <Media type={IMAGE} mediaURL={item.icon} />
                      </div> */}
                      <h2 className="choosePlan__box-title">
                        {/* TODO: Add plan type */}
                        {/* {getCourseBatch(courseData?.start_date)}/
                        {getCourseBatch(courseData?.end_date)} */}
                      </h2>
                      <div className="choosePlan__box-cost">
                        <i className="edi-rupee"></i>
                        {item?.total_fee_payable}
                      </div>
                      <div className="choosePlan__box-cta">
                        <CtaButton
                          btnCls={`btn btn__tertiary btn__tertiary--outline`}
                          btnText={"Buy Now"}
                          btnState={isLoading}
                          onClickProp={() => {
                            addToCart(item?.id, item?.new_feesgrp_id);
                          }}
                        />
                      </div>
                    </div>
                    <div className="choosePlan__box-bot" data-aos="zoom-in">
                      <ul>
                        {planLblArr.map((planLblItem, index) => (
                          <li
                            key={index}
                            data-aos="flip-right"
                            data-aos-delay={"50" * index}
                          >
                            {item[planLblItem.key] === "1" ||
                            item[planLblItem.key] === "0" ||
                            item[planLblItem.key] === "" ? (
                              item[planLblItem.key] ? (
                                <>
                                  {isMobile() && (
                                    <div className="choosePlan__box-lbl">
                                      {planLblItem.lbl}
                                    </div>
                                  )}
                                  <div className="choosePlan__box-nums">
                                    <i className="edi-right-tick"></i>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {isMobile() && (
                                    <div className="choosePlan__box-lbl">
                                      {planLblItem.lbl}
                                    </div>
                                  )}
                                  <div className="choosePlan__box-nums">
                                    <i className="edi-close"></i>
                                  </div>
                                </>
                              )
                            ) : (
                              <>
                                {isMobile() && (
                                  <div className="choosePlan__box-lbl">
                                    {planLblItem.lbl}
                                  </div>
                                )}
                                <div className="choosePlan__box-nums">
                                  {item[planLblItem.key]}
                                </div>
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChoosePlan;

import React from "react";

const AccordionChild = ({
  accordionConfig,
  accordionHead,
  bodyComponent,
  index,
  isActive,
  updateActiveIndex,
  innerRef = null,
}) => {
  const { showAccordArrow = true } = accordionConfig;

  const handleOpen = () => updateActiveIndex(index, !isActive);

  return (
    <>
      <div className="accordion__box" ref={innerRef}>
        <button
          type="button"
          aria-expanded={isActive}
          className="accordion__trigger"
          aria-controls={`section${index}`}
          id={`accordion${index}`}
          onClick={handleOpen}
        >
          {accordionHead}
          {showAccordArrow && (
            <div className="accordion__icon">
              <i className="edi-chevron-down"></i>
            </div>
          )}
        </button>
        <div
          id={`section${index}`}
          role="region"
          aria-labelledby={`accordion${index}`}
          className="accordion__body"
          hidden={!isActive}
        >
          {bodyComponent}
        </div>
      </div>
    </>
  );
};

export default AccordionChild;

import React from "react";

const DiscoverNowButton = ({
  extraAttri = {},
  onClickProp = () => {},
  clickRef,
  btnText,
}) => {
  const refProp = clickRef ? { ref: clickRef } : {};
  return (
    <>
      <button
        {...refProp}
        className="btn btn__discover btn__round"
        type="button"
        onClick={(e) => onClickProp(e)}
        {...extraAttri}
      >
        {btnText ? <span>{btnText}</span> : <span>Discover Now</span>}
        <i className="edi-next"></i>
      </button>
    </>
  );
};

export default DiscoverNowButton;

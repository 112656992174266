import React, { useCallback, useEffect } from "react";
import { Tabs } from "../tabs";
import { useGetBatchClassQuery } from "../../api/services/home";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";
import { useParams } from "react-router";
import { toKebabCase } from "../../utils";

const BatchType = ({
  isCustomActive = false,
  handleActive = () => {},
  active = null,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const homeState = useSelector((state) => state?.app?.home);
  const { data = [] } = useGetBatchClassQuery(homeState?.activeCourseType);

  const setActiveTab = useCallback(
    (tab) => {
      dispatch(home.setActiveClassType(tab));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data?.length) {
      const activeIndex = data?.findIndex(
        (x) => toKebabCase(x.class) === params.course
      );
      isCustomActive
        ? handleActive(data?.[0]?.id)
        : setActiveTab(data?.[activeIndex !== -1 ? activeIndex : 0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveTab, data]);

  return (
    <>
      <div className="subjectType__wrap">
        <Tabs
          customCls={"tabs__wrap--tertiary"}
          active={isCustomActive ? active : homeState?.activeClassType}
          data={data}
          handleClick={(tab) => {
            isCustomActive ? handleActive(tab) : setActiveTab(tab);
          }}
          type="object"
          displayKey="class"
        />
      </div>
    </>
  );
};

export default BatchType;

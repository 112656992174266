import React from "react";
import { IMAGE } from "../../common/config";
import DiscoverMoreButton from "../discoverMoreButton";
import Media from "../media";
import { useNavigate } from "react-router";
import { getPath } from "../../utils";

const AchieverCard = ({ thumbnail, title, rank, description, highlights }) => {
  const navigate = useNavigate();
  function handleDiscoverMore() {
    navigate(getPath("topRankers"));
  }
  return (
    <>
      <div className="achieverCard__box">
        <div className="achieverCard__grid">
          <div className="achieverCard__grid-lhs">
            <figure className="achieverCard__thumb">
              <Media
                type={IMAGE}
                mediaURL={
                  thumbnail
                    ? thumbnail
                    : "default-placeholder/achiever-placeholder.jpg"
                }
                mediaAttrib={{ alt: title }}
                hasBaseURL={false}
              />
            </figure>
            <div className="achieverCard__cta">
              <DiscoverMoreButton onClickProp={handleDiscoverMore} />
            </div>
          </div>
          <div className="achieverCard__grid-rhs">
            <h2 className="achieverCard__title">
              {title}
              {rank && <em className="achieverCard__ranker">{rank}</em>}
            </h2>

            {description && (
              <div
                className="achieverCard__caption"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
            )}
            {highlights && (
              <div className="achieverCard__keyword">
                <ul>
                  {highlights?.map((item, index) => (
                    <li key={index}>
                      <i className="edi-right-tick"></i>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AchieverCard;

import React from "react";
import Anchor from "../anchor";
import Media from "../media";

const AnnouncementCard = ({
  props,
  link,
  type,
  url,
  title,
  caption,
  published,
}) => {
  return (
    <>
      <Anchor
        hyperLink={link}
        {...props}
        component={
          <>
            <div className="announcement__card">
              {url && (
                <div className="announcement__card-thumb">
                  <Media type={type} mediaURL={url} {...props} />
                </div>
              )}
              {title && <div className="announcement__card-title">{title}</div>}
              {caption && (
                <div
                  className="announcement__card-caption"
                  dangerouslySetInnerHTML={{ __html: caption }}
                ></div>
              )}
              {published && (
                <div className="announcement__card-published">{published}</div>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default AnnouncementCard;

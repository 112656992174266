import React from "react";
import { IMAGE, isMobile } from "../../common/config";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import Media from "../media";
import { useDispatch } from "react-redux";
import { home } from "../../redux/actions";
import { useGetCommunityQuery } from "../../api/services/home";

const OurCommunitySwiper = () => {
  const dispatch = useDispatch();
  const { data } = useGetCommunityQuery();

  function formatCommunityData(item, index) {
    if (index === 0 || index % 3 === 0) {
      return [item];
    } else if (index !== 0 && (index - 1) % 3 === 0 && data?.[index + 1]) {
      return [data?.[index], data?.[index + 1]];
    } else if (index !== 0 && (index - 1) % 3 === 0) {
      return [data?.[index]];
    } else {
      return undefined;
    }
  }

  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={isMobile() ? 16 : 24}
        grabCursor={true}
        speed={3000}
        autoplay={{
          enabled: true,
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper_ourCommunity"
      >
        {data
          ?.map(formatCommunityData)
          ?.filter((x) => x !== undefined)
          ?.map((item, index) => (
            <SwiperSlide key={index}>
              {item?.map((childData, index) => (
                <div
                  key={index}
                  className="ourCommunity__thumb"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(home.setShowOurCommunityOverlay(childData.id));
                  }}
                >
                  {/* <Media
                  type={childData?.type}
                  mediaAttrib={
                    childData?.type === VIDEO
                      ? {
                          poster: childData?.thumbnail,
                        }
                      : undefined
                  }
                  mediaURL={
                    childData?.type === VIDEO
                      ? childData?.media_url
                      : childData?.thumbnail
                  }
                  hasBaseURL={false}
                /> */}
                  <Media
                    type={IMAGE}
                    // mediaAttrib={
                    //   childData?.type === VIDEO
                    //     ? {
                    //         poster: childData?.thumbnail,
                    //       }
                    //     : undefined
                    // }
                    mediaURL={childData?.thumbnail}
                    hasBaseURL={false}
                  />
                </div>
              ))}
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default OurCommunitySwiper;

import React from "react";
import InputLabel from "../inputLbl";
import { CtaButton } from "../../ctaButton";

const InputUpload = ({
  uploadFileLbl,
  uploadFileID,
  uploadFileAccept,
  uploadLblText,
  uploadNotes = [],
  handleCoverImageChange,
  fileUploadResult,
  fileUploadNameResult,
  handleRemoveImage,
  inputValidate,
  components,
  registerFunction,
}) => {
  // function reset

  const { ref = null, ...rest } = registerFunction
    ? registerFunction(uploadFileID)
    : {};

  return (
    <>
      {uploadFileLbl ? (
        <InputLabel inputLbl={uploadFileLbl} inputID={uploadFileID} />
      ) : (
        ""
      )}
      <div className={`upload ${inputValidate ? "invalid" : ""}`}>
        <input
          autoComplete="off"
          name={uploadFileID}
          type={"file"}
          id={uploadFileID}
          onChange={handleCoverImageChange}
          accept={uploadFileAccept}
          ref={ref}
          {...rest}
        />
        <div className="upload__output">
          <div className="upload__thumb">
            {fileUploadResult ? (
              <div className="upload__cta">
                <CtaButton
                  btnCls={"btn btn__icon btn__icon--primary btn__round btn__xs"}
                  iconCls={"edi-close"}
                  onClickProp={() => {
                    handleRemoveImage();
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {!fileUploadResult ? (
              <i className="edi-file"></i>
            ) : (
              <div className="upload__thumb-img">
                <img src={fileUploadResult} alt={"login-thumb"} />
              </div>
            )}
          </div>
          <div className="upload__content">
            <div className="upload__content-lbl">
              {!fileUploadNameResult ? uploadLblText : fileUploadNameResult}
            </div>
            <div className="upload__content-note">
              <ul>
                {uploadNotes.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {inputValidate ? components : ""}
    </>
  );
};

export default InputUpload;

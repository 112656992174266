import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";

const JoinOurTeam = () => {
  return (
    <>
      <section className="section__row  section--joinOurTeam">
        <div className="main__container">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h1"}
              title={"Join Our Team"}
            />
            <SectionCaption
              caption={`<p>Explore exciting opportunities to join our progressive team at Ednovate, where your talents can make a meaningful impact on the future of professional coaching and education.</p>`}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinOurTeam;

import React from "react";
import Anchor from "../anchor";
import { CtaButton } from "../ctaButton";

const Socials = ({ arrProps, isAnchor = false }) => {
  function handleClick() {
    if (navigator?.share) {
      navigator?.share({
        url: window.location.href,
        text: `Join this course`,
        title: "Share this course",
      });
    }
  }
  return (
    <>
      <div className="social__list">
        {arrProps.map((item, index) =>
          isAnchor ? (
            <Anchor key={index} {...item} />
          ) : (
            <CtaButton
              btnCls={"btn btn__icon"}
              key={index}
              {...item}
              onClickProp={handleClick}
            />
          )
        )}
      </div>
    </>
  );
};

export default Socials;

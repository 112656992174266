import React from "react";
import { CtaButton } from "../ctaButton";
import { getPath, isLoggedIn } from "../../utils";
import { Link } from "react-router-dom";

const UserProfile = () => {
  return (
    <>
      <div className="userProfile">
        <Link to={isLoggedIn() ? getPath("profile") : getPath("login")}>
          <CtaButton
            btnCls={"btn btn__icon"}
            iconCls={"edi-profile"}
            onClickProp={() => {}}
            ariaLabel={"Click to know your profile details."}
          />
        </Link>
      </div>
    </>
  );
};

export default UserProfile;

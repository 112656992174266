import React from "react";

const CourseListSection = ({ components }) => {
  return (
    <>
      <section className="course__wrap" data-aos="fade-up">
        {components}
      </section>
    </>
  );
};

export default CourseListSection;

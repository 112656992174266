import React, { useEffect, useState } from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { CtaButton } from "../ctaButton";
import {
  useGetSectionHeadingsQuery,
  useGetSixPillarsQuery,
} from "../../api/services/home";

const SixPillars = () => {
  const [activeIndex, setActiveIndex] = useState();
  const { data } = useGetSixPillarsQuery();
  const { data: sectionHeadings } = useGetSectionHeadingsQuery();

  useEffect(() => {
    setActiveIndex(0);
  }, [data]);

  return (
    <>
      <section className="section__row  section--sixPillars" data-aos="fade-up">
        <div className="main__container">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={sectionHeadings?.six_pillar?.six_pillar_title}
            />
            <SectionCaption
              caption={sectionHeadings?.six_pillar?.six_pillar_text}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="sixPillars__list">
            {data?.map((item, index) => (
              <CtaButton
                key={index}
                btnCls={`btn__unset `}
                onClickProp={() => {
                  setActiveIndex(index);
                }}
                components={
                  <>
                    <div
                      className={`sixPillars__box ${
                        index === activeIndex ? "active" : ""
                      }`}
                    >
                      <div className="sixPillars__head">
                        <i className="edi-arrow-top"></i> <h2>{item.title}</h2>
                        <i className="edi-arrow-down"></i>
                      </div>
                      {index === activeIndex && (
                        <div className="sixPillars__body animated">
                          <div className="sixPillars__grid">
                            <div
                              className="sixPillars__thumb"
                              data-aos="zoom-in-up"
                            >
                              <Media
                                type={IMAGE}
                                mediaURL={item?.thumbnail}
                                mediaAttrib={{ alt: item.title }}
                                hasBaseURL={false}
                              />
                            </div>
                            <div
                              className="sixPillars__dtls"
                              data-aos="flip-up"
                            >
                              <h2 className="sixPillars__title">
                                {item.title}
                              </h2>
                              <div
                                className="sixPillars__caption"
                                data-aos="flip-up"
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                }
                extraAttri={{
                  "data-aos": "fade-right",
                  "data-aos-delay": 100 * index,
                }}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SixPillars;

import React, { useState } from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import TopRankerCard from "../topRankerCard";
import { CtaButton } from "../ctaButton";
import { Overlays } from "../overlays";
import TestifyDetailCard from "../testifyDetailCard";
import { useGetSectionHeadingsQuery } from "../../api/services/home";
import { isMobile } from "../../common/config";

const TopRankerListWithCategory = ({ data }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: sectionHeadings } = useGetSectionHeadingsQuery();

  function toggleOverlay(index = 0) {
    setIsOverlayOpen((state) => !state);
    setActiveIndex(index);
  }

  const [maxItems, setMaxItems] = useState(4);

  return (
    <>
      <section className="section__row  section--topRankersHex">
        <div className="main__container ">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={sectionHeadings?.top_ranker?.top_ranker_title}
            />
            <SectionCaption
              caption={sectionHeadings?.top_ranker?.top_ranker_text}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="topRanker__list">
            {data?.slice(0, maxItems)?.map((item, index) => (
              <CtaButton
                key={item?.id}
                onClickProp={() => {
                  return undefined;
                }}
                components={
                  <>
                    <TopRankerCard
                      key={item?.id}
                      isHex={true}
                      id={item?.id}
                      thumb={item.thumbnail}
                      name={item.name}
                      rank={item.rank}
                      title={item.title}
                    />
                  </>
                }
              />
            ))}
          </div>
          {maxItems < data?.length && (
            <div className="topRanker__cta">
              <CtaButton
                btnCls={"btn btn__primary"}
                iconCls={"edi-next"}
                btnText={"Load More"}
                onClickProp={() => {
                  setMaxItems((prev) => prev + (isMobile() ? 2 : 4));
                }}
              />
            </div>
          )}
        </div>
      </section>

      {isOverlayOpen && (
        <Overlays
          overlayName={"testify"}
          overlayConfig={{
            noOverlayClose: true,
            noOverlayHead: false,
            noOverlayBody: true,
            noOverlayFoot: false,
            onClickProp: toggleOverlay,
          }}
        >
          <TestifyDetailCard
            heading="Testimonial"
            title={data?.[activeIndex]?.name}
            description="Description"
            thumbnail={data?.[activeIndex]?.thumbnail}
          />
        </Overlays>
      )}
    </>
  );
};

export default TopRankerListWithCategory;

import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <>
      <div className="breadcrumbs__list">
        <ul>
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index}>
              {index > 0 && <i className="edi-breadcrumb"></i>}
              {breadcrumb.link ? (
                <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
              ) : (
                <span>{breadcrumb.label}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Breadcrumbs;

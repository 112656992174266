import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks";
import InputSearchBox from "../forms/inputSearchBox";

const Dropdown = ({
  label,
  list,
  value,
  icon,
  handleClick,
  language,
  customCls,
  toggleOnHover = false,
  autoComplete = false,
}) => {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);
  const [autoCompleteText, setAutoCompleteText] = useState("");

  useOnClickOutside(inputRef, () => {
    setIsActive(false);
  });

  useEffect(() => {
    if (isActive && autoComplete) {
      inputRef?.current?.children?.[0]?.children?.[0]?.children?.[1]?.children?.[0]?.children?.[0]?.children?.[0]?.focus();
    }
  }, [autoComplete, isActive]);

  return (
    <>
      <div
        className={`dropdown ${customCls ? customCls : ""} ${
          isActive ? "active" : ""
        }`}
        onMouseEnter={() => {
          toggleOnHover && setIsActive(true);
        }}
        onMouseLeave={() => {
          toggleOnHover && setIsActive(false);
        }}
        ref={inputRef}
      >
        {label ? <div className="dropdown__lbl">{label}</div> : ""}
        <div className="dropdown__box">
          <div
            className="dropdown__value"
            onClick={() => {
              if ((autoComplete && !isActive) || !autoComplete)
                setIsActive(!isActive);
            }}
          >
            {icon ? <i className={icon}></i> : ""}
            <span dangerouslySetInnerHTML={{ __html: value?.title }}></span>
            {autoComplete ? (
              isActive ? (
                <div className="dropdown__autocomplete">
                  <InputSearchBox
                    srchPlaceholder={"type here..."}
                    searchConfig={{
                      parentExtraCls: "input--searchBox",
                    }}
                    handleInput={(e) => {
                      setAutoCompleteText(e.target.value);
                    }}
                    inputValue={autoCompleteText}
                    clearInputValue={() => setAutoCompleteText("")}
                  />
                </div>
              ) : null
            ) : null}
            {/* {value} */}
            <div className="value__icon">
              {isActive ? (
                <i className="edi-chevron-up"></i>
              ) : (
                <i className="edi-chevron-down"></i>
              )}
            </div>
          </div>
          {isActive ? (
            <div className="dropdown__list">
              <ul>
                {list
                  ?.filter((x) => {
                    if (!autoComplete || !autoCompleteText) {
                      return true;
                    } else {
                      return x.title
                        ?.toLowerCase()
                        .startsWith(autoCompleteText.toLowerCase());
                    }
                  })
                  ?.slice(0, 100)
                  ?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleClick(item);
                        setIsActive(!isActive);
                        setAutoCompleteText("");
                      }}
                      className={value?.id === item?.id ? "active" : ""}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: language ? language[item] : item?.title,
                        }}
                      ></span>
                    </li>
                  ))}
                {!list
                  ?.filter((x) => {
                    if (!autoComplete || !autoCompleteText) {
                      return true;
                    } else {
                      return x.title
                        ?.toLowerCase()
                        .startsWith(autoCompleteText.toLowerCase());
                    }
                  })
                  ?.slice(0, 100)?.length && (
                  <li className="disabled">
                    <span>No Options Available</span>
                  </li>
                )}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Dropdown;

import React from "react";

const ValidationText = ({ extraCls, validationText }) => {
  return (
    <>
      <div className={`errMsg ${extraCls ? extraCls : ""}`}>
        <span>{validationText}</span>
      </div>
    </>
  );
};

export default ValidationText;

import React from "react";
import Media from "../media";

const EventCard = ({
  mediaType,
  mediaPath,
  mediaConfig,
  publish,
  title,
  handleClick = () => {},
}) => {
  return (
    <>
      <div className="event__card" title={title} onClick={handleClick}>
        <div className="event__card-thumb">
          <Media type={mediaType} mediaURL={mediaPath} {...mediaConfig} />
        </div>
        <div className="event__card-info">
          <div className="event__card-title">{title}</div>
          <div className="event__card-publish">{publish}</div>
        </div>
      </div>
    </>
  );
};

export default EventCard;

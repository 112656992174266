import React from "react";
import Media from "../media";

const TestimonialCard = ({
  cardConfig,
  title,
  caption,
  rank,
  category,
  swiper = null,
}) => {
  return (
    <>
      <div className="testimonial__card">
        <div className="testimonial__card-thumb">
          <Media {...cardConfig} swiper={swiper} />
          <div className="testimonial__card-category">{category}</div>
        </div>
        <div className="testimonial__card-dtls">
          <h3 className="testimonial__card-title">{title}</h3>
          {rank && <em className="achieverCard__ranker">{rank}</em>}
          <div
            className="testimonial__card-caption"
            dangerouslySetInnerHTML={{ __html: caption }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;

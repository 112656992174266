import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";

const Marquee = ({ customCls, list }) => {
  return (
    <>
      <div className="marquee__wrap">
        <div className={`marquee__strip ${customCls ? customCls : ""}`}>
          <>
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              loop={true}
              freeMode={true}
              autoplay={{
                enabled: true,
                delay: 1,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, FreeMode]}
              centerInsufficientSlides={true}
              speed={8000}
              className="mySwiper_ticker"
            >
              {list?.map((item, index) => (
                <SwiperSlide key={index}>
                  <i className="edi-star-bullet"></i> {item}
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
      </div>
    </>
  );
};

export default Marquee;

import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
// import { useMap } from "react-leaflet/hooks";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { IMG_BASE } from "../../common/config";

const customIcon = new Icon({
  iconUrl: `${IMG_BASE}favicon/apple-icon.png`,
  iconSize: [38, 38],
});

const Map = ({ markers = [] }) => {
  return (
    <MapContainer
      center={markers?.[0]?.position}
      zoom={12}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers?.map((marker) => (
        <Marker position={marker?.position} icon={customIcon}>
          <Popup>{marker?.address}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;

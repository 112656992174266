import React, {
  useEffect,
  // , useState
} from "react";
import { Outlet } from "react-router";
import Header from "../header";
import Footer from "../footer";
import Aos from "aos";
import {
  useGetMasterDataQuery,
  useGetSEODataQuery,
} from "../../api/services/master";
import { useCurrentRoute, useGA } from "../../hooks";
// import Media from "../media";
// import { VIDEO } from "../../common/config";
import Loader from "../loader";
import { useGetNewCoursesQuery } from "../../api/services/courses";
const STICKY_OFFSET = 80;

const AppLayout = () => {
  useGA();
  window.addEventListener("scroll", (event) => {
    let scroll = window.scrollY;
    // console.log(scroll)
    let header = document.querySelector("header");
    if (scroll > STICKY_OFFSET) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  });

  const currentRoute = useCurrentRoute();
  const { data: seoData } = useGetSEODataQuery(currentRoute?.id);

  useEffect(() => {
    function updateSEO(data) {
      if (data) {
        document.title = data?.meta_title || "Ednovate";
        document
          .querySelector(`meta[name="description"]`)
          .setAttribute("content", data?.meta_description);
        document
          .querySelector(`meta[name="keywords"]`)
          .setAttribute("content", data?.meta_keywords?.join(","));
        document
          .querySelector(`meta[property="og:image"]`)
          .setAttribute("content", data?.og_image);
      }
      // else {
      //   document.title = seo[0]?.title;
      //   document
      //     .querySelector(`meta[name="description"]`)
      //     .setAttribute("content", seo[0]?.description);
      //   document
      //     .querySelector(`meta[name="keywords"]`)
      //     .setAttribute("content", seo[0]?.keywords?.join(","));
      // }
    }
    updateSEO(seoData);
  }, [seoData]);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (currentRoute?.id !== "free_resources") {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [currentRoute]);

  // const isSplashScreenLoaded = localStorage?.getItem("edno_splash");
  // const [showSplashScreen, setShowSplashScreen] = useState(
  //   !isSplashScreenLoaded
  // );

  const { isLoading } = useGetMasterDataQuery();
  const { isLoading: isNewCoursesLoading } = useGetNewCoursesQuery();
  if (isLoading || isNewCoursesLoading) {
    return <Loader />;
  }

  // if (showSplashScreen) {
  //   return (
  //     <div className="splashScreen__wrap">
  //       <Media
  //         type={VIDEO}
  //         mediaAttrib={{
  //           autoPlay: true,
  //           controls: false,
  //           loop: false,
  //           muted: true,
  //         }}
  //         mediaURL={"splash-screen.mp4"}
  //         handleEnded={() => {
  //           localStorage?.setItem("edno_splash", 1);
  //           setShowSplashScreen(false);
  //           console.log("ENDED");
  //         }}
  //       />
  //     </div>
  //   );
  // }

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default AppLayout;

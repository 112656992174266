import React from "react";
import { Overlays } from "../overlays";
import { Swiper, SwiperSlide } from "swiper/react";
import Media from "../media";
import { VIDEO, YOUTUBE_VIDEO } from "../../common/config";

const HomeBannerOverlay = ({
  show = false,
  toggleOverlay,
  type,
  image_link,
  video_link,
}) => {
  return (
    <>
      {show && (
        <Overlays
          overlayName={"community"}
          overlayConfig={{
            noOverlayHead: false,
            noOverlayFoot: false,
            onClickProp: () => {
              toggleOverlay();
            },
          }}
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            // centeredSlides={true}
            grabCursor={true}
            className="mySwiper__overlayCommunity"
          >
            <SwiperSlide>
              <div className="ourCommunity__overlay">
                <div className="ourCommunity__overlay-thumbnail">
                  <Media
                    type={type}
                    mediaAttrib={
                      type === VIDEO
                        ? {
                            poster: image_link,
                          }
                        : undefined
                    }
                    mediaURL={
                      type === VIDEO || type === YOUTUBE_VIDEO
                        ? video_link
                        : image_link
                    }
                    hasBaseURL={false}
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Overlays>
      )}
    </>
  );
};

export default HomeBannerOverlay;

import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import LearningType from "../learningType";
import CourseType from "../courseType";
import { useSelector } from "react-redux";
import { useGetSectionHeadingsQuery } from "../../api/services/home";
import BatchType from "../batchType";

const AvailableCourses = () => {
  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );

  const { data: sectionHeadings } = useGetSectionHeadingsQuery();

  return (
    <>
      <section
        className="section__row  section--availableCourses"
        data-aos="zoom-out"
      >
        <div className="main__container">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={sectionHeadings?.available?.available_title}
            />
            <SectionCaption
              caption={sectionHeadings?.available?.available_text}
            />
          </div>
        </div>
        <div className="main__container">
          <LearningType tabList={masterData?.learning_modes ?? []} />
        </div>
        <div className="main__container">
          <CourseType tabList={masterData?.batch ?? []} />
        </div>
        <div className="main__container">
          <BatchType />
        </div>
      </section>
    </>
  );
};

export default AvailableCourses;

import React from "react";

const SectionCaption = ({ customCls, caption, extraAttrib }) => {
  return (
    <>
      {caption && (
        <div
          className={`section__caption ${customCls ? customCls : ""}`}
          {...extraAttrib}
          dangerouslySetInnerHTML={{ __html: caption }}
        ></div>
      )}
    </>
  );
};

export default SectionCaption;
